import '@/css/app.css';

// import Swiper from 'swiper';
// import { Navigation, Pagination, Scrollbar } from 'swiper/modules';
// import { SwiperOptions } from 'swiper/types';

import Splide from '@splidejs/splide';
import '@splidejs/splide/css';

class App {
    menuButton = document.getElementById('menu-button');
    menuButtonOpen = this.menuButton!.querySelector('.menu-button-open');
    menuButtonClose = this.menuButton!.querySelector('.menu-button-close');
    termButtons = document.querySelectorAll('.term');
    
    public async init() {
        this.menuButton?.addEventListener('click', (e) => {
            e.preventDefault();
            document.body.classList.toggle('menu-open');
            this.toggleMenuButton();
        })

        this.termButtons?.forEach((button) => {
            button.addEventListener('click', (e) => {
                const elm:HTMLElement = e.currentTarget as HTMLElement;
                if (elm) {
                    elm.classList.add('active');
                }
            });
        });
    }

    private toggleMenuButton() {
        this.menuButtonOpen!.classList.toggle('hidden');
        this.menuButtonClose!.classList.toggle('hidden');
    }
}

let app = new App();
app.init();

let options = {
    rootMargin: "0px",
    threshold: 0,
};

window.addEventListener('scroll', (evt) => {
    let scrollHeight: number = Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight
      );      
    let innerHeight: number = window.innerHeight;
    let scrollY: number = window.scrollY;
    document.body.classList.add('scroll');
    document.body.style.setProperty('--scroll', (scrollY / (scrollHeight - innerHeight)) as unknown as string);

    let scrollEndTimer;
    clearTimeout(scrollEndTimer);
    scrollEndTimer = setTimeout(() => {
        document.body.classList.remove('scroll');
    }, 500);

  }, false);

window.addEventListener('scrollend', () => {
    document.body.classList.remove('scroll');
});
  
const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
        const waves = document.querySelector('.footer-waves') as HTMLDivElement;
        if (waves) {
            if (entry.isIntersecting) {
                let elem = entry.target;
                waves.classList.add('hide');
            } else {
                waves.classList.remove('hide');
            }
        }
    });
};

const mainFooter = document.querySelector('#main-footer') as HTMLDivElement;
if (mainFooter) {
    let observer = new IntersectionObserver(intersectionCallback, options);
    observer.observe(mainFooter);
}

// const swiperParams: SwiperOptions =  {
//     modules: [Navigation, Scrollbar],
//     mousewheel: true,
//     navigation: {
//         nextEl: '.swiper-button-next',
//         prevEl: '.swiper-button-prev',
//     },
//     cssMode: true,
//     freeMode: true,
//     keyboard: true,
//     spaceBetween: 0,
//     slidesPerView: 'auto',
//     centeredSlides: true,
//     loop: true,
    // breakpoints: {
    //     320: {
    //         slidesPerView: 'auto',
    //     },
    //     740: {
    //         slidesPerView: 2,
    //     },
    //     1110: {
    //         slidesPerView: 3,
    //     },
    //     1480: {
    //         slidesPerView: 4,
    //     },
    //     1850: {
    //         slidesPerView: 5,
    //     }
    // }
// };
// const swiper = new Swiper('.mySwiper', swiperParams);
// swiper.on('lock', (swiper) => {
//     console.log('locked', swiper);
// });

const prevButton = document.querySelector('.splide .custom-splide__arrow--prev');
const nextButton = document.querySelector('.splide .custom-splide__arrow--next');
const slide = document.querySelector<HTMLDivElement>('.splide__slide');

var splide = new Splide( '.splide', {
    autoHeight: true,
    autoWidth: true,
    focus: 'center',
    gap: 20,
    pagination: false,
    type   : 'loop',
    classes: {
        arrows: 'splide__arrows custom-splide__arrows',
		arrow : 'splide__arrow custom-splide__arrow',
		prev  : 'splide__arrow--prev custom-splide__arrow--prev',
		next  : 'splide__arrow--next custom-splide__arrow--next',
    }
} );

splide.on( 'overflow', function ( isOverflow ) {
    splide.options = {
        autoHeight: true,
        arrows    : isOverflow,
        drag      : isOverflow,
        clones    : isOverflow ? undefined : 0, // Toggle clones
        focus      : isOverflow ? 'center': 0,
        classes: {
            arrows: 'splide__arrows your-class-arrows',
            arrow : 'splide__arrow your-class-arrow',
            prev  : 'splide__arrow--prev your-class-prev',
            next  : 'splide__arrow--next your-class-next',
        }
    };

    splide.go(0);
});

splide.mount();

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
      console.log("HMR")
    });
}